
import React from 'react';
import { graphql } from 'gatsby';
import SiteFinderForm from '../components/SiteFinderForm';

export default function SiteFinder(props) {
  const sitedata = props.data.siteYaml;
  const siteAddresses = sitedata.screener.locations.addresses;
  return (
    <div>
      <h1>Site Finder</h1>
      <SiteFinderForm locations={siteAddresses} />
    </div>
  );
};

export const query = graphql`
  query siteLocationsQ($locale: String, $variation: String) {
    siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      screener {
        locations {
          addresses {
            city
            lat
            lng
            name
            state
            street
            zip
            country
          }
        }
      }
    }
  }`;
