/**
 * SiteFinderForm provides an input field for postal codes by which we can
 * sort the sites by proximity.
 * For attribution to Google see
 * https://developers.google.com/maps/documentation/geocoding/policies
 */
import React, {useState, useEffect, useRef} from 'react';
import sortfn from '../util/geotools';
import { Loader } from '@googlemaps/js-api-loader';
import '../styles/sitefinder.css';

import glogo from '../images/powered_by_google_on_white.png';

export default function SiteFinderForm(props) {
  const [sortedSites, setSortedSites] = useState([]);
  const placesAPIkey = 'AIzaSyCMEV1mP_nO_tKOimhKlk98fQCrtoo0Gy0';
  const postalCodeRef = useRef(null);
  const geocoder = useRef(null);

  function findPostalCodeCoords(address) {
    return new Promise((resolve, reject) => {
      geocoder.current.geocode({'address': address}, function(results, status) {
        if (status==='OK') {
          const loc = results[0].geometry.location;
          const point = {lat:loc.lat() , lng:loc.lng()};
          console.log('Found', results[0].formatted_address, point);
          resolve(point);
        } else {
          reject(new Error(`Unable to find location for postal code ${address}.`));
        }
      });
    });
  }

  function findClosestSites(e) {
    e.preventDefault();
    if (geocoder.current) {
      const searchTerm = `${postalCodeRef.current.value}, US`;
      findPostalCodeCoords(searchTerm).then(function(data) {
        const newsort = sortfn(data, props.locations);
        setSortedSites(newsort);
        console.log(`Closest to zip ${searchTerm} ->`, newsort[0]);
      }).catch(function(error) {
        console.log('Sorry!', error);
      });
    } else console.log('No geocoder to find closest site ->', postalCodeRef.current.value);
  }

  useEffect(() => {
    const loader = new Loader({ apiKey: placesAPIkey, version: 'weekly', libraries: ['places'] });
    loader.load().then((google) => {
      geocoder.current = new google.maps.Geocoder();
    }).catch(e => {
      console.log('Unable to load geocoder!');
    });
  }, []);

  return (
    <div className="site-finder">
      <form className="postal-code-form">
        <label htmlFor="postalcode">Postal code:
          <input type="text" name="postalcode" id="postalcode" ref={postalCodeRef} />
        </label><button id="find-btn" onClick={findClosestSites}>Find Closest Sites</button>
        <div><img src={glogo} alt="Powered by Google" className="img-responsive" /></div>
      </form>
      <div className="locations">
        <div className="row">
          <div className="col-sm-6">
            <div className="first">
              {sortedSites.map((item, index) => {
                return (
                  <div key={`addr_${index}`} className="locations-row">
                    <div className="row">
                      {renderSite(item)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function renderSite(site) {
  return (
    <div className="row">
      <div className="col-sm-7">
        <div className="loc-title">
          <p><strong>{site.name}</strong></p>
        </div>
        <div className="street-block">
          <div className="thoroughfare">{site.street}</div>
        </div>
        <div className="addressfield-container-inline locality-block country-US">
          <span className="locality">{site.city}, </span>
          <span className="state">{site.state} </span>
          <span className="postal-code">{site.zip}</span>
        </div>
      </div>
      <div className="col-sm-2">
        <span className="miles">{site.dist} Mi</span>
      </div>
    </div>
  );
}
